import './index.scss'

import React, { useState, useRef } from 'react'
import T from 'prop-types'
import { isBefore, format } from 'date-fns'
import { lowerCase } from 'lodash'
import BEMHelper from 'react-bem-helper'

import scrollTo from '../../helpers/scroll-to'
import getPosition from '../../helpers/get-position'

import Icon from '../Icon'

const bem = new BEMHelper('journey-detailed')
export default function JourneyDetailed({ items, precarriage, oncarriage }) {
  const wrapperRef = useRef(null)
  const [showThePast, setShowPast] = useState(false)

  const handleToggle = () => {
    const value = !showThePast
    setShowPast(value)

    if (value) {
      const to = getPosition(wrapperRef.current).y - 10

      scrollTo({
        to,
        duration: 500,
      })
    }
  }

  const isActiveState = (
    today,
    thisEta,
    thisEtd,
    isFirst,
    previousMoves,
    thisMoves,
  ) =>
    isBefore(thisEta, today) &&
    isBefore(today, thisEtd) &&
    (isFirst || previousMoves) &&
    !thisMoves

  const isPastState = (today, thisEtd, thisOrLaterHasMoves) =>
    isBefore(thisEtd, today) && thisOrLaterHasMoves

  const isCarriageActive = (
    carriageType,
    previousItemPast,
    carriagePast,
    hasHaulageLogs,
    nextItemActive,
  ) => {
    return carriageType !== 'P'
      ? previousItemPast && !carriagePast
      : hasHaulageLogs && !nextItemActive
  }

  const renderCarriageItem = (
    carriageType,
    destinationName,
    originName,
    haulageLogs,
    nextItemActive,
    previousItemPast,
    containerMovesCompleted,
  ) => {
    const hasHaulageLogs = haulageLogs && haulageLogs.length > 0
    const carriagePast =
      carriageType === 'P' ? nextItemActive : containerMovesCompleted
    const carriageActive = isCarriageActive(
      carriageType,
      previousItemPast,
      carriagePast,
      hasHaulageLogs,
      nextItemActive,
    )
    const carriageFuture = !carriageActive && !carriagePast
    return (
      <li
        key="precarriage"
        {...bem('item', {
          hidden: carriageType === 'P' && !showThePast && pastIsHidden,
          active: carriageActive,
          past: carriagePast,
        })}
      >
        <div {...bem('point')}>
          <span
            {...bem('icon', {
              carriage: true,
              future: carriageFuture,
            })}
          >
            <Icon icon="truck" />
          </span>
          <span {...bem('progress')} />
        </div>
        <div {...bem('info')}>
          <h2 {...bem('heading')}>
            {carriageType === 'O' ? 'On Carriage' : 'Pre Carriage'}
          </h2>
          <dl {...bem('details')}>
            <div>
              <dt {...bem('key')}>Origin</dt>
              <dd>{originName}</dd>
            </div>
            <div>
              <dt {...bem('key')}>Destination</dt>
              <dd>{destinationName}</dd>
            </div>
          </dl>
          {haulageLogs && haulageLogs.length > 0 && (
            <div {...bem('containers')}>
              <h3 {...bem('key')}>Container moves</h3>
              <ul>
                {haulageLogs.map(
                  (
                    {
                      containerNumber,
                      podReceivedSent,
                      confirmedDeliveryDate,
                      requestedDeliveryDate,
                    },
                    index,
                  ) => (
                    <li
                      key={`${index}-${containerNumber}`}
                      {...bem('container')}
                    >
                      {containerNumber || '[Missing number]'}
                      {requestedDeliveryDate
                        ? ` requested ${format(
                            requestedDeliveryDate,
                            'D. MMM',
                          )}`
                        : ''}
                      {confirmedDeliveryDate
                        ? ` confirmed ${format(
                            confirmedDeliveryDate,
                            'D. MMM',
                          )}`
                        : ''}
                      {!requestedDeliveryDate && !confirmedDeliveryDate
                        ? ' confirmed'
                        : ''}
                      {podReceivedSent ? ' with proof of delivery' : ''}
                    </li>
                  ),
                )}
              </ul>
            </div>
          )}
        </div>
      </li>
    )
  }

  const count = items && items.length
  const today = new Date()
  const pastElements = items.filter(
    ({ etd, containerMovesCompleted }) =>
      isBefore(etd, today) && containerMovesCompleted,
  )
  const lastIndexHidden =
    pastElements && items.lastIndexOf(pastElements[pastElements.length - 1])
  const pastIsHidden = lastIndexHidden > 0
  const hasPreCarriage = precarriage && precarriage.length > 0
  const hasOnCarriage = oncarriage && oncarriage.length > 0
  const preCarriagePast = isBefore(items[0].eta, today)
  const onCarriageActive = isBefore(items[items.length - 1].etd, today)
  const onCarriagePast = isBefore(items[items.length - 1].etd, today)
  const pastElementsCount = lastIndexHidden + (hasPreCarriage ? 1 : 0)

  /* eslint-disable complexity */

  return (
    <div {...bem('')} ref={wrapperRef}>
      <ol
        {...bem('list', {
          'show-past': showThePast,
        })}
      >
        {pastIsHidden && (
          <li key="more" {...bem('more')}>
            <button {...bem('toggle')} onClick={handleToggle}>
              <span {...bem('toggle-arrow', { expanded: showThePast })}>
                <Icon icon="chevron" direction="down" {...bem('toggle-icon')} />
              </span>
              <span {...bem('toggle-text')}>
                {showThePast
                  ? `Hide old events (${pastElementsCount})`
                  : `Show old events (${pastElementsCount})`}
              </span>
            </button>
          </li>
        )}

        {hasPreCarriage &&
          precarriage.map(
            ({ carriageType, destinationName, originName, haulageLogs }) => {
              return renderCarriageItem(
                carriageType,
                destinationName,
                originName,
                haulageLogs,
                preCarriagePast,
                false,
                false,
              )
            },
          )}

        {items.map(
          (
            {
              eta,
              etd,
              etaDate,
              etaTime,
              portName,
              portId,
              portType,
              events,
              etdDate,
              etdTime,
              vesselName,
              voyageNumber,
              containerMovesCompleted,
            },
            index,
          ) => {
            const isFirst = index === 0
            const previousItem = index !== 0 ? items[index - 1] : null
            const previousMoves = previousItem
              ? previousItem.containerMovesCompleted
              : false
            const thisMoves = containerMovesCompleted

            const inPortActive = isActiveState(
              today,
              eta,
              etd,
              isFirst,
              previousMoves,
              thisMoves,
            )
            const inPortPast = isPastState(today, etd, lastIndexHidden >= index)

            const showEtd =
              index + 1 !== count && portType !== 'Discharge transshipment'
            const hidePast = pastIsHidden && lastIndexHidden > index
            const inPortFuture = !inPortPast && !inPortActive

            return (
              <React.Fragment key={index}>
                <li
                  {...bem('item', {
                    hidden: hidePast,
                    past: inPortPast,
                    active: inPortActive,
                  })}
                >
                  <div {...bem('point')}>
                    <span
                      {...bem('icon', {
                        future: inPortFuture,
                      })}
                    >
                      <Icon icon="portSmall" />
                    </span>
                    <span {...bem('progress')} />
                  </div>

                  <div {...bem('info')}>
                    <h2 {...bem('heading')}>{portType}</h2>

                    <time {...bem('date')}>
                      <span>ETA</span> {format(etaDate, 'D. MMM')},{' '}
                      {etaTime && etaTime.substr(0, 5)} – <span>ETD</span>{' '}
                      {format(etdDate, 'D. MMM')}, {etdTime.substr(0, 5)}
                    </time>

                    <dl {...bem('details')}>
                      <div>
                        <dt {...bem('key')}>Port</dt>
                        <dd>
                          {portName} ({portId})
                        </dd>
                      </div>

                      <div>
                        <dt {...bem('key')}>Vessel</dt>
                        <dd>{vesselName}</dd>
                      </div>
                      <div>
                        <dt {...bem('key')}>Voyage</dt>
                        <dd>{voyageNumber}</dd>
                      </div>
                    </dl>
                    {events && events.length > 0 && (
                      <div {...bem('containers')}>
                        <h3 {...bem('key')}>Container moves</h3>
                        <ul>
                          {events.map(
                            ({
                              containerNumber,
                              eventType,
                              terminalName,
                              smdgCode,
                            }) => (
                              <li
                                key={`${index}-${containerNumber}`}
                                {...bem('container')}
                              >
                                {containerNumber} {lowerCase(eventType)} to
                                terminal {terminalName} ({smdgCode})
                              </li>
                            ),
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                </li>

                {showEtd && (
                  <li
                    {...bem('item', {
                      hidden: hidePast,
                      past: inPortPast,
                    })}
                  >
                    <div {...bem('point')}>
                      <span
                        {...bem('icon', {
                          ship: true,
                          future: !inPortPast,
                        })}
                      >
                        <Icon icon="shipfront" />
                      </span>
                    </div>

                    <div {...bem('info')}>
                      <h2 {...bem('heading')}>At sea</h2>
                    </div>
                  </li>
                )}
              </React.Fragment>
            )
          },
        )}

        {hasOnCarriage &&
          oncarriage.map(
            ({
              carriageType,
              destinationName,
              originName,
              haulageLogs,
              containerMovesCompleted,
            }) => {
              return renderCarriageItem(
                carriageType,
                destinationName,
                originName,
                haulageLogs,
                false,
                onCarriageActive,
                containerMovesCompleted,
              )
            },
          )}
      </ol>
    </div>
  )
}

JourneyDetailed.propTypes = {
  items: T.array.isRequired,
  precarriage: T.oneOfType([T.object, T.array]),
  oncarriage: T.oneOfType([T.object, T.array]),
}
