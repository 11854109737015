import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import axios from 'axios'
// import { addDays, setHours, setMinutes, formatISO } from 'date-fns'

import Article, { Title, Text } from '../components/Article'
import Hero, { Overlap } from '../components/Hero'
import Icon from '../components/Icon'
import Section from '../components/Section'
import Tracking from '../components/Tracking'
import InlineAction from '../components/InlineAction'
import Loader from '../components/Loader'
import RenderContent from '../components/RenderContent'
import ErrorHeader from '../components/ErrorHeader'

import imageHelper from '../helpers/image-helper'
import config from '../../config.json'
const API_SOURCE = `${config.backend_server_url}_api/`

export default class TrackingPage extends Component {
  state = {
    booking: null,
    precarriage: null,
    oncarriage: null,
    schedule: [],
    loading: false,
    error: null,
    query: null,
  }

  componentDidMount() {
    const query = this.getURLdata()
    if (query) {
      this.setState({ query })
      this.findBooking(query)
    }
  }

  getURLdata = () => {
    const { location } = this.props

    const query =
      location.search &&
      location.search
        .split('?q=')
        .join('')
        .split('&')[0]

    return location.search ? decodeURIComponent(query) : null
  }

  hasLoadedOrDischargedEvents = events =>
    events.some(
      row =>
        row.event_type === 'LOADED' ||
        row.event_type === 'DISCHARGED' ||
        row.event_type === 'TERMDEPART',
    )

  hasProofOfDeliveryEvent = events =>
    events.some(row => row && row.pod_received_sent !== null)

  parseBooking = data =>
    data.map(item => ({
      eta: `${item.eta_date}T${item.eta_time}`, // Time is set in the Norwegian timezone
      etd: `${item.etd_date}T${item.etd_time}`, // Time is set in the Norwegian timezone
      etaDate: item.eta_date,
      etaTime: item.eta_time,
      etdDate: item.etd_date,
      etdTime: item.etd_time,
      portId: item.port_id,
      portName: item.port_name,
      portTypeId: item.port_type_id,
      portType: item.port_type,
      voyageNumber: item.voyage_number,
      vesselName: item.vessel_name,
      events: item.events.map(row => ({
        containerNumber: row.container_number,
        eventType: row.event_type,
        eventDatetime: row.event_datetime,
        eventLocode: row.event_locode,
        terminalName: row.terminal_name,
        smdgCode: row.smdg_code,
        locode: row.locode,
        portName: row.port_name,
      })),
      containerMovesCompleted: this.hasLoadedOrDischargedEvents(item.events),
    }))

  parseCarriage = data =>
    data.map(item => ({
      carriageType: item.carriage_type,
      destinationName: item.destination_name,
      originName: item.origin_name,
      haulageLogs: item.haulage_logs.map(row => ({
        arrivalNoticeSent: row.arrival_notice_sent,
        carriageType: row.carriage_type,
        confirmedDeliveryDate: row.confirmed_delivery_date,
        containerNumber: row.container_number,
        destinationName: row.destination_name,
        originName: row.origin_name,
        podReceivedSent: row.pod_received_sent,
        requestedDeliveryDate: row.requested_delivery_date,
        rykkReceivedSent: row.rykk_received_sent,
      })),
      containerMovesCompleted: this.hasProofOfDeliveryEvent(item.haulage_logs),
    }))

  findBooking = query => {
    if (query) {
      this.setState({
        booking: null,
        precarriage: null,
        oncarriage: null,
        schedule: [],
        loading: true,
        error: null,
        query,
      })

      // DEMO
      // this.setState({
      //   booking: demoBooking,
      //   schedule: this.parseBooking(portCallsDemo),
      //   loading: false,
      // })

      if (window) {
        window.history.pushState(
          {},
          'Update URL params without refresh',
          `/tracking/?q=${encodeURIComponent(query)}`,
        )
      }

      axios
        .all([
          axios.get(
            `${API_SOURCE}trackntrace?search=${encodeURIComponent(query)}`,
          ),
        ])
        .then(
          axios.spread(({ data }) => {
            const booking = data && data.booking
            const schedule = data && this.parseBooking(data.port_calls)
            const precarriage = data && this.parseCarriage(data.precarriage)
            const oncarriage = data && this.parseCarriage(data.oncarriage)
            this.setState({
              booking,
              schedule,
              oncarriage,
              precarriage,
              loading: false,
              error: null,
            })
          }),
        )
        .catch(() => {
          this.setState({
            loading: false,
            error: true,
          })
        })
    }
  }

  render() {
    const { data } = this.props

    const {
      booking,
      schedule,
      precarriage,
      oncarriage,
      loading,
      error,
      query,
    } = this.state

    if (!data.ievv) {
      return <ErrorHeader />
    }

    const { heroImage } = data.ievv

    const getImage = imageHelper(data)
    const hero = heroImage ? getImage(heroImage) : {}

    return (
      <>
        <Hero {...hero}>
          <InlineAction
            label="Booking number"
            placeholder="Search"
            action={<Icon icon="search" strong />}
            onSubmit={this.findBooking}
            value={query}
          />
        </Hero>

        {loading && <Loader />}

        {error && (
          <Overlap key="none" spaceless>
            <Article>
              <Title>Could not find '{query}'</Title>
              <Text>
                Are you sure this is a valid booking number, Waybill number or a
                container number for a shipment with NCL? Please contact us if
                you have problems and we will be happy to help you out.
              </Text>
            </Article>
          </Overlap>
        )}

        {booking && (
          <Overlap key={booking} spaceless>
            <Tracking
              bookingNumber={booking.booking_number}
              title={booking.booking_status}
              customerRef={booking.customer_ref}
              precarriage={precarriage}
              oncarriage={oncarriage}
              data={schedule}
            />
          </Overlap>
        )}

        <Section type="light">
          <RenderContent data={data} />
        </Section>
      </>
    )
  }
}

TrackingPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.any,
}

TrackingPage.defaultProps = {
  topTask: true,
}

export const query = graphql`
  query {
    ievv(pagetypeid: { eq: "ncl_backend_pageframework.Tracking" }) {
      ...ArticleData
    }
    ...GetImages
  }
`
