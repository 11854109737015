import './index.scss'

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'
import Container from '../Container'

const bem = new BEMHelper('inline-action')

export default class InlineAction extends PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    action: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onSubmit: PropTypes.func,
  }

  static defaultProps = {
    onSubmit: () => {},
  }

  handleSubmit = event => {
    event.preventDefault()
    this.props.onSubmit(this.input.value)
  }

  render() {
    const { label, action, placeholder, value, ...props } = this.props

    return (
      <Container small {...bem('')}>
        <form {...bem('form')} onSubmit={this.handleSubmit}>
          <label {...bem('group')}>
            <span {...bem('label')}>{label}</span>

            <input
              type="text"
              ref={ref => {
                this.input = ref
              }}
              placeholder={placeholder}
              defaultValue={value}
              autoFocus
              {...bem('input')}
              {...props}
            />
          </label>

          <button type="submit" {...bem('action')}>
            {action}
          </button>
        </form>
      </Container>
    )
  }
}
