import './index.scss'

import React from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'
import { format } from 'date-fns'
// import Icon from '../Icon'
import Container from '../Container'
import JourneyDetailed from '../JourneyDetailed'

// import Button from '../Button'
// import { format } from 'date-fns'

const bem = new BEMHelper('tracking')

export default function Tracking({
  bookingNumber,
  title,
  from,
  to,
  data,
  precarriage,
  oncarriage,
  customerRef,
}) {
  const itemsCount = data && data.length
  const lastItem = itemsCount && data ? data[itemsCount - 1] : null
  const { portName, etaDate, etaTime } = lastItem

  return (
    <div {...bem('')}>
      <Container small {...bem('summary')}>
        <h2 {...bem('title')}>{title}</h2>
        {etaDate && (
          <p>
            ETA in port {portName} {format(etaDate, 'D. MMM')},{' '}
            {etaTime.substr(0, 5)}
          </p>
        )}
        <div {...bem('summary-details')}>
          {bookingNumber && (
            <dl {...bem('section')}>
              <dt {...bem('sub-title')}>Bookingnumber</dt>
              <dd {...bem('address')}>{bookingNumber.toUpperCase()}</dd>
            </dl>
          )}

          {from && from.address && (
            <dl {...bem('section')}>
              <dt {...bem('sub-title')}>From</dt>
              <dd {...bem('address')}>{from.address}</dd>
            </dl>
          )}

          {to && to.address && (
            <dl {...bem('section')}>
              <dt {...bem('sub-title')}>To</dt>
              <dd {...bem('address')}>{to.address}</dd>
            </dl>
          )}

          {customerRef && (
            <dl {...bem('section')}>
              <dt {...bem('sub-title')}>Customer reference</dt>
              <dd {...bem('address')}>{customerRef}</dd>
            </dl>
          )}
        </div>
      </Container>

      <div {...bem('details')}>
        <JourneyDetailed
          items={data}
          precarriage={precarriage}
          oncarriage={oncarriage}
        />
      </div>
    </div>
  )
}

Tracking.propTypes = {
  bookingNumber: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  from: PropTypes.object,
  to: PropTypes.object,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  precarriage: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  oncarriage: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  customerRef: PropTypes.string,
}

Tracking.defaultProps = {
  code: '',
}
