import './index.scss'

import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('loader')

const WAVE_HEIGHT = 1.2
const WAVE_WIDTH = 1.8
const WAVE_SPEED = 0.3
const WAVE_Y_POS = 24
const WIDTH = 62
const HEIGHT = 34
const MARGIN = 2

export default function Loader ({ children }) {
  const lineRef = useRef()
  const fillRef = useRef()
  const xs = Array.from(Array(WIDTH)).map((x, i) => i + 1)

  useEffect(() => {
    let t = 0
    let abort = false

    function animate () {
      const points = xs.map(x => {
        const y = WAVE_Y_POS + WAVE_HEIGHT * Math.sin((x + t) / WAVE_WIDTH)
        return [x, y]
      })

      const linePath = `M${points.map(p => `${p[0]},${p[1]}`).join(' L')}`
      const fillPath = `M0,${HEIGHT} L ${points
        .map(p => `${p[0]},${p[1]}`)
        .join(' L')} ${WIDTH},${HEIGHT}z`

      t += WAVE_SPEED

      if (!abort && lineRef.current && fillRef.current) {
        lineRef.current.setAttribute('d', linePath)
        fillRef.current.setAttribute('d', fillPath)
        window.requestAnimationFrame(animate)
      }
    }

    animate()

    return () => {
      abort = true
    }
  }, [])

  return (
    <figure {...bem('')}>
      <svg
        {...bem('icon')}
        viewBox={`-${MARGIN} -${MARGIN} ${WIDTH + MARGIN * 2} ${HEIGHT +
          MARGIN * 2}`}
      >
        <g {...bem('boat')}>
          <polyline {...bem('line')} points="25,20 25,7 14,7 14,16" />
          <rect {...bem('line')} x="18" y="1" width="3" height="6" />
          <rect {...bem('line')} x="29" y="16" width="9" height="5" />
          <rect {...bem('line')} x="29" y="11" width="9" height="5" />
          <polyline
            {...bem('line')}
            points="47,15.2 47,11 38,11 38,16 44.2,16"
          />
          <polyline
            {...bem('line')}
            points="50,25 54.4,14.3 43.8,16 41.8,21 17.8,21 16.7,16 11.8,16 13.9,25.2"
          />
        </g>
        <path ref={fillRef} {...bem('fill')} />
        <path ref={lineRef} {...bem('line')} />
      </svg>

      <figcaption {...bem('text')}>{children || 'Loading...'}</figcaption>
    </figure>
  )
}

Loader.propTypes = {
  children: PropTypes.any,
}

// https://codepen.io/winkerVSbecks/pen/JYVqVe
// https://varun.ca/chillwave/
